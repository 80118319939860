import HtmlComponentComponent from '@wix/thunderbolt-elements/src/components/HtmlComponent/viewer/HtmlComponent';


const HtmlComponent = {
  component: HtmlComponentComponent
};


export const components = {
  ['HtmlComponent']: HtmlComponent
};


// temporary export
export const version = "1.0.0"
